import mainjson from './main';
import { Level } from '../utils/LoginFunctions';
import { Resend } from '../utils/ApiFunctions';

function checkIf(data) {
    if (parseInt(Level()) <= 0) {
        return true;
    }
    else {
        return false;
    }
}

async function reSend(id){
    
    let result =  await Resend(id);
    if (result.error == 0) {
        alert("Email verzonden");
    }
    else {
        alert("Email niet verzonden");
    }
}

export default function GetData() {
        let data = {
        "apitype":"orders",
        "id":"order_id",
        "subidtext":"Order [!order_id!] - [!user_name!], ",
        "options":["edit","delete"],
        "nameSingle":"order",
        "nameMultiple":"orders",
        "export": [
            {
                name:"ID",
                field:"order_id",
            },
            {
                name:"Tickets",
                field:"count",
            },
            {
                name:"Date",
                field:"order_date",
            },
            {
                name:"Client",
                field:"user_name",
            },
            {
                name:"Client e-mail",
                field:"user_email",
            },
            {
                name:"Codes used",
                field:"codecount",
            },
            {
                name:"Amount",
                field:"order_amount",
                func: (val) => {return parseFloat(val/100).toFixed(2);}
            },
            {
                name:"Discount",
                field:"order_discount",
                func: (val) => {return parseFloat(val/100).toFixed(2);}
            },
            {
                name:"Paid",
                field:"order_amount",
                func: (val,record) => {return parseFloat((record.order_amount-record.order_discount)/100).toFixed(2);}
            }
        ],
        "extraoptions":[
            {
                "name":"Resend e-mail",
                "method":reSend,
            },
            {
                "name":"Tickets",
                "page":"/ordertickets",
            },
        ],
        "fields":{
            "field1":{
                "name":"ID",
                "field":"order_id",
                "type":"Display",
                "list":true,
            },
            "field2":{
                "name":"Tickets",
                "field":"count",
                "type":"Display",
                "list":true,
            },
            "field2b":{
                "name":"Tickets",
                "field":"tickets",
                "type":"DisplayOrder",
                "list":false,
            },
            
            "field3":{
                "name":"Date",
                "field":"order_date",
                "type":"DateTimeInput",
                "required":true,
                "list":true,
                "displayEdit":true
            },
            "field4":{
                "name":"Client",
                "field":"user_name",
                "type":"Display",
                "list":true,
            },   
            "field5":{
                "name":"Client e-mail",
                "field":"user_email",
                "type":"Display",
                "list":true,
            },
            "field6":{
                "name":"Codes",
                "field":"codecount",
                "type":"Display",
                "list":true,
            }
        }
    }

    return data;
}